import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import GatsbyImage from "gatsby-image"

const TemplateImages = ({ src, className = "", style }) => {
  const data = useStaticQuery(graphql`
    query {
      aj1: file(relativePath: { eq: "notebooks/aj1.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aj2: file(relativePath: { eq: "notebooks/aj2.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aj3: file(relativePath: { eq: "notebooks/aj3.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ap: file(relativePath: { eq: "notebooks/ap.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      as1: file(relativePath: { eq: "notebooks/as1.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bay: file(relativePath: { eq: "notebooks/bay.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cafe: file(relativePath: { eq: "notebooks/cafe.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      do: file(relativePath: { eq: "notebooks/do.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      duck: file(relativePath: { eq: "notebooks/duck.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ez1: file(relativePath: { eq: "notebooks/ez1.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ez2: file(relativePath: { eq: "notebooks/ez2.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ez3: file(relativePath: { eq: "notebooks/ez3.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fri: file(relativePath: { eq: "notebooks/fri.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fri1: file(relativePath: { eq: "notebooks/fri1.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fri2: file(relativePath: { eq: "notebooks/fri2.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gk: file(relativePath: { eq: "notebooks/gk.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gr1: file(relativePath: { eq: "notebooks/gr1.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gs: file(relativePath: { eq: "notebooks/gs.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hm1: file(relativePath: { eq: "notebooks/hm1.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ko1: file(relativePath: { eq: "notebooks/ko1.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lp: file(relativePath: { eq: "notebooks/lp.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mc: file(relativePath: { eq: "notebooks/mc.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ry: file(relativePath: { eq: "notebooks/ry.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sh1: file(relativePath: { eq: "notebooks/sh1.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sh2: file(relativePath: { eq: "notebooks/sh2.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sk: file(relativePath: { eq: "notebooks/sk.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      st: file(relativePath: { eq: "notebooks/st.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sw: file(relativePath: { eq: "notebooks/sw.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      val1: file(relativePath: { eq: "notebooks/val1.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      val2: file(relativePath: { eq: "notebooks/val2.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      val3: file(relativePath: { eq: "notebooks/val3.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      wd: file(relativePath: { eq: "notebooks/wd.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      we: file(relativePath: { eq: "notebooks/we.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      wr: file(relativePath: { eq: "notebooks/wr.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 460, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  if (!data[src]) {
    console.error("image not found:", src)
  }
  return (
    <GatsbyImage
      fluid={data[src].childImageSharp.fluid}
      className={`img--${src} ${className}`}
      style={style}
    />
  )
}
export default TemplateImages
