import React, { useEffect, useState } from "react"
// import Img from "gatsby-image"

import DownArrowSVG from "../assets/svg/down-arrow.svg"

import "./notebook.scss"

import Img from "../assets/images/notebook-images"

import MB from "../assets/images/notebooks/mb.mp4"
import CHA from "../assets/images/notebooks/fc_chacha_1.mp4"
import RIC from "../assets/images/notebooks/Ricc Alphabet.mp4"
import CCC from "../assets/images/notebooks/CCC.mp4"
import UP from "../assets/images/notebooks/UP.mp4"
import { useWindowScroll, useInterval } from "react-use"
import { motion } from "framer-motion"

import SEO from "../components/seo"
import ScrollDownArrow from "../components/ScrollDownArrow"

const NotebooksData = [
  {
    title: "Movebot",
    images: [],
    media: [MB],
    text: `Shifting valuable data around in the cloud can be risky business.
    Movebot takes care of everything with a service that’s clean,  simple and
    reliable. So that’s exactly how we built their new brand. More to come soon.`,
  },
  {
    title: "Espresso245",
    images: ["cafe"],
    media: [],
    text: `Espresso245 only make great coffee and food. True artisans in both areas,  they wanted to make some noise in the local business district and get everyone hooked on the authentic cafe experience. Coffee give-aways with clever copy did the trick nicely.`,
  },
  {
    title: "",
    images: ["sh1", "sh2"],
    media: [],

    text: `Bespoke luxury linen brand Summerhouse Linen needed an equally bespoke and luxury logomark to position their new company. We created a classic monogram based on H & S letterforms – engaging, simple, and a little bit sumptuous.`,
  },
  {
    title: "",
    images: ["duck"],
    media: [],

    text: `This is Ralph Friday. Ralph stands sentry at the studio front doors, welcoming our friends and visitors and eliciting squeals of delight from passing chubby-fingered toddlers. He's equal parts cute and mute and excellent sensory therapy for those of us who aren't cool enough to have a studio pupper. Need a cuddle? Come on in.`,
  },
  {
    title: "",
    images: ["ry"],
    media: [],

    text: `Here's Friday's CD, photographer and all-round fruit and flora stylist setting up shots and design for up-coming collateral for fresh-thinking client, Ryman Healthcare.`,
  },
  {
    title: "",
    images: [],
    media: [CCC],
    text: `Our partnership with the Christchurch City Council continues with this neat little campaign encouraging everyone to do the right thing when camping in Christchurch and surrounding areas. Watch this space for the full monty.`,
  },
  {
    title: "",
    images: ["we"],
    media: [],

    text: `When you want good brand and creative you come to us. When you need the best sparkies you go to Winchester Electrical. We had the pleasure of illustrating cute characters for Rob and his team. They're as colourful as they are professional`,
  },
  {
    title: "",
    images: ["fri1"],
    media: [],

    text: `There’s only one thing better than pizza on a Friday, and that’s Friday on a pizza. The consummate designer, Alice baked an end-of-week treat for the team. It only got as far as Craig.`,
  },
  {
    title: "",
    images: ["bay"],
    media: [],

    text: `We've been working with real estate specialists Bayleys Canterbury for the past few years, most recently with an artwork that celebrates their partnership with the Christchurch Art Gallery Te Puna O Waiwhetu. This large-format abstract depicting the front facia of the gallery was painted by Friday's CD Craig. `,
  },
  {
    title: "",
    images: ["aj1", "aj2", "aj3"],
    media: [],

    text: `Based in New Zealand, and distributing throughout Australasia, Aunt Jenny provides crafters with high quality equipment, supplies and accessories, and the inspiration to create. We recently updated their brand, led by a logomark that partners a classic printed ‘A’ with a hand-crafted ‘J’ visually pairing the storytelling and retail aspects of their brand.`,
  },
  {
    title: "",
    images: ["as1"],
    media: [],

    text: `Asana for Africa is part of a larger initiative supporting the Africa Yoga Project. We created a strong visual identity for Asana, with bright, bold colours and a simple creative idea that shows love for Africa.`,
  },
  {
    title: "",
    images: ["fri2"],
    media: [],

    text: `Like the builder who's always too busy to finish his own house, our own website has been embarrassingly conspicuous in its absence. Done at last, we marked the occasion with a couple of quiet lemonades`,
  },
  {
    title: "",
    images: ["hm1"],
    media: [],

    text: `We’re working on a new brand identity for a great new client who works in the commercial development sector.  The disruptive brand launches soon. BRB.`,
  },
  {
    title: "",
    images: [],
    media: [RIC],
    text: `Friday’s long-term relationship with local Riccarton Primary School continues with the creation of a bespoke alphabet to lead their new brand. Bright, fun letterforms were developed using ideas, objects and activities kids can relate to. `,
  },
  {
    title: "",
    images: ["val1", "val2", "val3"],
    media: [],

    text: `Values to live and die by. These three shape everything we do, encourage and create. If authentic, bold and well-considered brand development sounds the business to you, let's talk.`,
  },
  {
    title: "",
    images: ["gr1"],
    media: [],

    text: `Top bloke, client and owner of Red + Black Construction Grant Waghorn, known as Wags, dropped by the studio for a hello. We love that. He was wearing his new branded work gear so we lined him up for a pretty pic.`,
  },
  {
    title: "",
    images: ["ap"],
    media: [],

    text: `From complex full noise floral illustration right down to the simplest application of their logomark, the new brand for garden and landscape specialists KO & CO is being recognised on a national scale. As per their new aprons, sometimes simplest is best.`,
  },
  {
    title: "",
    images: ["do"],
    media: [],

    text: `Double-O specialise in Lean and Agile business methodologies and processes that help organisations do dangerously good business. The brand platform for these industry disruptors has been rolled out across everything from high-end comms to their upper bodies. Here, their (not so) corporate gear.`,
  },
  {
    title: "",
    images: [],
    media: [CHA],
    text: `Cha Cha cafe sits in the heart of London’s Soho district, and brings a vibrant energy to the local hospitality scene. Bright colours and bold typography work together to create a great look and perfect vibe for a humming little coffee joint.`,
  },
  {
    title: "",
    images: ["ko1"],
    media: [],

    text: `Believe it or not we cut and handcrafted four logomarks from hand-drawn illustrations. Each a work of art in itself, these seasonal-flavoured assets were sculpted for the full colour, full-of-life brand for landscape specialists KO & CO. For the full story go to our Projects page.`,
  },
  {
    title: "",
    images: ["mc"],
    media: [],

    text: `A blast from the past – Craig’s first iMac. Still fully operational, although more sentimentally valuable than functional now, this is the beast that helped launch Craig's career. It occupies pride of place in the studio as an icon of its time; and a very large paperweight.
    `,
  },
  {
    title: "",
    images: ["sw"],
    media: [],

    text: `We had the privilege of working with Bayleys Canterbury and the Canterbury Crusaders on Bayleys' amazing anti-bullying initiative. Ace, A Horsey Tail of Courage is a poignant, charming and witty story of friendship and redemption, featuring one of the real-life Crusaders Horses. Written by Friday's Katy, Ace had the backing of Bullying-Free NZ and the Ministry of Education, while some of NZ's best-known faces fronted up to promote it.`,
  },
  {
    title: "",
    images: ["wd"],
    media: [],

    text: `One of our first pieces of work with Double-O Consultants was their (not so) corporate profile – an exploration of disruptive design that features a series of icons and die-cuts. See our Projects page for the fuller brand development.`,
  },
  // {
  //   title: "",
  //   images: ["fri"],
  //   media: [],

  //   text: ``,
  // },
  {
    title: "",
    images: ["ez1", "ez2", "ez3"],
    media: [],

    text: `Ezimac is one of New Zealand’s cleverest Mac-based IT service and sales companies. We recently produced a retro artwork for their offices by resurrecting classic old keyboards from purveyors of obsolete technology. Three hundred and fifty plucked keys were given new life with an homage to the first full-colour Mac icon set.`,
  },
  {
    title: "",
    images: ["lp"],
    media: [],

    text: `Our work often goes beyond the traditional creative design and campaigning approach. Following our rebrand of iconic furniture design company Southern Creations, we pitched the idea for a rimu lamp based on the logomark design. The prototype is underway. See it soon.`,
  },
  {
    title: "",
    images: ["st"],
    media: [],

    text: `Stratus Homes is a new building company in central Christchurch. We recently developed an engaging logomark and brand look and feel for them based on the architectural letterforms of the company name. Simple, structured and smart.`,
  },
  {
    title: "",
    images: [],
    media: [UP],
    text: `Education is a saturated and often tricky sector for new brands to effectively stand out. We’re creating a bold brand for a new international eduction provider in Auckland, using a fresh colour palette and an innovative, versatile brand pattern approach. Full story to come.`,
  },
  {
    title: "",
    images: ["sk"],
    media: [],

    text: `If eyes are the window to the soul, then this brand needs nothing but the most authentic approach. We can’t say too much at this point as everything is still in development, but take a peek back soon.`,
  },
  {
    title: "",
    images: ["wr"],
    media: [],

    text: `Locality played a large part in the development of this identity for a Christchurch community's healthcare centre. The centre is situated at a natural bend in the Avon River, so we designed a logomark that combined the ideas of location and river-flow with the universal medical symbol.`,
  },
]

const NoteBook = ({ notebook }) => {
  const { images, media, text, title } = notebook

  const items = []

  let NoteCarousel = () => {
    const [activeIndex, setActiveIndex] = useState(0)

    useInterval(() => nextSlide(), 2000)

    const nextSlide = () => {
      setActiveIndex((activeIndex + 1) % (images.length + media.length))
    }

    return (
      <div className="note-carousel">
        {images.map((img, index) => (
          <motion.div
            key={index}
            className="absolute inset-0"
            animate={{ opacity: index === activeIndex ? 1 : 0 }}
          >
            <Img src={img} />
          </motion.div>
        ))}
        {media.map((vid, index) => (
          <video
            src={vid}
            key={index}
            className="absolute top-0 left-0 w-full"
            autoPlay
            playsInline
            muted
            loop
          ></video>
        ))}
      </div>
    )
  }

  return (
    <div className="notebook">
      <div className="notebook-image bg-gray-200">
        <NoteCarousel />
      </div>
      <div className="notebook-text bg-white">
        {/* <h4 className="notebook-text__title">{title}</h4> */}
        <p className="notebook-text__paragraph">{text}</p>
      </div>
    </div>
  )
}

const NotebookPage = ({ data }) => {
  const notes = NotebooksData

  return (
    <div className="notebook-page page-wrapper flex flex-wrap">
      <SEO
        title="Notebook"
        description="We do a lot of stuff. Most of it is fun, even the stuff we do for
            free. We do too much for free, which sux. But oh well. We’re still
            here, and still enjoying it."
      />
      <div className="intro-section">
        <div className="panel panel--text bg-white w-1/2">
          <h2 className="inline">
            Here's a snapshot of what we've been up to lately, as well as a
            sneak peek at one or two other amazing projects we're onto.
            Honestly, some of it's so new we can really only lift the corner of
            the page right now. Check in later for more.
            <br />
            <br />
            Have a scroll through.
          </h2>
        </div>
        <div className="intro-notebooks-container">
          {notes.map(
            (notebook, index) =>
              index < 3 && <NoteBook notebook={notebook} key={index} />
          )}
        </div>
      </div>
      <div className="flex flex-wrap">
        {notes.map(
          (notebook, index) =>
            index >= 3 && <NoteBook notebook={notebook} key={index} />
        )}
      </div>
    </div>
  )
}

export default NotebookPage

// export const query = graphql`
//   query MyQuery {
//     allPrismicNote {
//       edges {
//         node {
//           id
//           data {
//             paragraph {
//               raw
//               text
//             }
//             title {
//               raw
//               text
//             }
//             media {
//               raw
//             }
//             image {
//               localFile {
//                 childImageSharp {
//                   fluid(quality: 100) {
//                     ...GatsbyImageSharpFluid_withWebp
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `
